import { render, staticRenderFns } from "./DomainConfigManager.vue?vue&type=template&id=7ea1455a&scoped=true&"
import script from "./DomainConfigManager.vue?vue&type=script&lang=js&"
export * from "./DomainConfigManager.vue?vue&type=script&lang=js&"
import style0 from "./DomainConfigManager.vue?vue&type=style&index=0&id=7ea1455a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ea1455a",
  null
  
)

export default component.exports